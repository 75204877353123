import { useState } from "react";
import CustomRadioInput from "../../../components/formElement/CustomRadioInput";
import CustomInput from "../../../components/formElement/CustomInput";
import CustomLabel from "../../../components/formElement/CustomLabel";
import Spinner from "../../../components/Spinner";
import axios from "axios";
import swal from "sweetalert";
import { Link, useSearchParams } from "react-router-dom";

const isMilad = () => {
  if (["simcard.miladcard.com", "9it30m.ir", "simcard.padiza.ir", "simcard.seekaweb.ir"].includes(window.location.hostname)) {
    return true;
  }
  return false;
};

const Modal = (props) => {
  let pwaAccessToken = localStorage.getItem("pwaAccessToken");

  let CountryDataType = props.CountryDataType;
  let selectionList = props.product.selectionList;
  if (selectionList) {
    selectionList = selectionList.split("\n");
    selectionList = selectionList.filter((item) => item);
  } else {
    selectionList = [];
  }

  const [showAlerAcceptRule, setShowAlerAcceptRule] = useState(false);
  const [acceptRule, setAcceptRule] = useState(false);
  const [showRule, setShowRule] = useState(false);
  const [showFeature, setShowFeature] = useState(false);

  const [activeDivertCall, setActiveDivertCall] = useState(false);
  const [firstDuration, setFirstDuration] = useState(isMilad() ? 3 : 1);
  const [firstCharge, setFirstCharge] = useState(0);
  const [typeNumber, setTypeNumber] = useState("random");
  const [address, setAddress] = useState();
  const [codePost, setCodePost] = useState();
  const [selectedFileFront, setSelectedFileFront] = useState();

  const [mobile, setMobile] = useState();
  const [code, setCode] = useState();
  const [name, setName] = useState();

  const [firstPriority, setFirstPriority] = useState();
  const [secondPriority, setSecondPriority] = useState();
  const [thirdPriority, setThirdPriority] = useState();

  const [isRegistered, setIsRegistered] = useState(false);
  const [step, setStep] = useState("getFirstDetail");
  const [activeSpinner, setActiveSpinner] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const aff = queryParams.get("aff");

  const resetState = () => {
    setFirstDuration(isMilad() ? 3 : 1);
    setFirstCharge(0);

    setStep("getFirstDetail");
    setMobile("");

    setFirstPriority("");
    setSecondPriority("");
    setThirdPriority("");

    setActiveSpinner(false);
    setShowAlerAcceptRule(false);
    setAcceptRule(false);
    setShowFeature(false);
    setShowRule(false);
    setActiveDivertCall(false);
    setTypeNumber("random");
  };

  let apiUrl = window.location.origin;
  if (window.location.hostname === "localhost") {
    apiUrl = "https://arianltd.ir";
  }

  const goNextStep = () => {
    let pwaAccessToken = localStorage.getItem("pwaAccessToken");

    if (!acceptRule) {
      setShowAlerAcceptRule(true);
      return;
    }
    if (!pwaAccessToken) {
      setStep("getMobile");
      return;
    }
    if (typeNumber === "rond") {
      console.log("goNextStep rond");
      pay();
      return;
    } else if (CountryDataType == "virtual") {
      // pay()
      setStep("chooseMethod");
      return;
    } else {
      setStep("getUserOrderData");
      return;
    }
  };

  const pay = (payAsCrypto = false) => {
    setActiveSpinner(true);
    let formData = new FormData();
    formData.append("pid", props.product.id);
    formData.append("payAsCrypto", payAsCrypto);
    formData.append("firstDuration", firstDuration);
    if (CountryDataType == "virtual" && activeDivertCall) {
      formData.append("activeDivertCall", 1);
    }
    if (CountryDataType == "simcard" && typeNumber !== "rond") {
      formData.append("firstCharge", firstCharge);
      formData.append("address", address);
      formData.append("codePost", codePost);
      formData.append("imageFilePictureFrontHead", selectedFileFront[0]);
    }

    formData.append("typeNumber", typeNumber);
    if (typeNumber === "selected") {
      formData.append("firstPriority", firstPriority);
      formData.append("secondPriority", secondPriority);
      formData.append("thirdPriority", thirdPriority);
    }

    let pwaAccessToken = localStorage.getItem("pwaAccessToken");

    axios
      .post(`${apiUrl}/panel/v1/user/pay`, formData, {
        headers: {
          Authorization: `Bearer ${pwaAccessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setActiveSpinner(false);
        // return
        if (res.data.hasOwnProperty("invoiceId")) {
          if (res.data.typeNumber === "rond") {
            resetState();
            swal("تبریک", "سفارش شما با موفقیت ثبت شد، همکاران ما به زودی لیست شماره های رند را برای شما ارسال خواهند کرد");
            return;
          }
        }
        window.location.assign(`${window.location.origin}/panel/site/quick-buy-new?id=${res.data.url}&token=${pwaAccessToken}`);
      })
      .catch(async (res) => {
        setActiveSpinner(false);
      });
  };

  const Login = () => {
    setActiveSpinner(true);

    let formData = new FormData();
    formData.append("mobile", mobile);
    if (name) {
      formData.append("name", name);
    }
    if (aff) {
      formData.append("aff", aff);
    }
    if (step === "getCode" && code) {
      formData.append("code", code);
    }

    axios
      .post(`${apiUrl}/panel/v1/auth/register`, formData, {})
      .then(async (res) => {
        setActiveSpinner(false);
        if (res.data.access_token) {
          await localStorage.setItem("pwaAccessToken", res.data.access_token);
          goNextStep();
          return;
        } else if (res.data.mobile) {
          setIsRegistered(res.data.isRegistered);
          setStep("getCode");
        } else {
        }
      })
      .catch(async (res) => {
        swal("خطا", "کد وارد شده اشتباه میباشد", "error");
        setActiveSpinner(false);
      });
  };

  const CalculatePrice = (isDoller = false) => {
    let price = 0;

    if (CountryDataType == "simcard") {
      price += props.product.priceSimCrad > 0 ? parseFloat(props.product.priceSimCrad) : 0; // simCardEstoni
      if (props.product.disableFaalSaziTamas == 0) {
        price += 2; // FaalSaziTamas
      }
      price += parseFloat(firstCharge) + (parseFloat(firstCharge) * 10) / 100; // chargeAvalie
    }
    if (CountryDataType == "virtual" && activeDivertCall) {
      price += 2;
    }
    price += parseFloat(props.product.priceNumberEachMonth); // hazine RahAndazi
    price += parseFloat(firstDuration) * parseFloat(props.product.priceNumberEachMonth); // modat Zaman
    if (isDoller === false) {
      price *= parseFloat(props.dollarPrice); // ش
      if (CountryDataType == "virtual") {
        price += 10000; // 10 tomam sharge avlie baray shomare majazi
      }
      price += Math.ceil((price * 9) / 100); // arzeshAfzode

      if (typeNumber === "selected") {
        price += 500000;
      }
    } else {
      price += (price * 1) / 100;
      price += 1;
    }

    if (typeNumber === "rond") {
      price = 0;
    }
    return price.toLocaleString();
  };

  return (
    <>
      <button
        onClick={() => resetState()}
        type="button"
        className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-md leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target={`#Modal${props.id}`}
      >
        برای سفارش کلیک کنید <br />
        {typeNumber !== "rond" && (
          <>
            {parseInt(props.product.pishShomare) === 3725 ? (
              <>
                <p className="my-1">قیمت دائمی </p>
              </>
            ) : (
              // <><p className="my-1">قیمت {( props.product.pishShomare === '0044-770' ? 6 : 3)} ماه </p></>
              <>
                <p className="my-1">شروع قیمت از</p>
              </>
            )}
            {CalculatePrice()} تومان
          </>
        )}
      </button>

      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id={`Modal${props.id}`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
                خرید
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {step === "getFirstDetail" && (
              <div className="modal-body  p-4 ">
                <span className="bg-red-600 text-white  p-1 rounded-md mb-4">
                  <b onClick={() => setShowFeature(!showFeature)}>برای مطالعه مشخصات محصول کلیک کنید </b>
                </span>

                <div className={`bg-gray-700 mt-3 text-right text-white p-4  rounded-md  ${showFeature ? "block" : "hidden"} `}>
                  <div dangerouslySetInnerHTML={{ __html: props.product.description }} />
                </div>

                {parseInt(props.product.pishShomare) !== 3725 && (
                  <div className="mt-5 mb-5">
                    <p className="font-bold text-lg mb-3 text-right pr-7 ">
                      مدت زمان فعال ماندن شماره خریداری شده توسط شما: ( این زمان قابل تمدید است و شما می توانید از پنل کاربری نیز قبل از انقضا شماره را تمدید کنید )
                    </p>
                    <div className="grid mb-3">
                      {props.product.pishShomare !== "0044-770" && !isMilad() && (
                        <div className="flex form-check form-check-inline p-1">
                          <CustomRadioInput name={`duration${props.product.id}`} id={"1"} value={1} onChange={setFirstDuration} label="یک ماه" checked={isMilad() ? false : true} />
                        </div>
                      )}
                      {props.product.pishShomare !== "0044-770" && (
                        <div className="flex form-check form-check-inline p-1">
                          <CustomRadioInput name={`duration${props.product.id}`} id={"1"} value={3} onChange={setFirstDuration} label="سه ماه" checked={isMilad() ? true : false} />
                        </div>
                      )}
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput
                          name={`duration${props.product.id}`}
                          id={"1"}
                          value={6}
                          onChange={setFirstDuration}
                          label="شش ماه"
                          checked={props.product.pishShomare === "0044-770" ? true : false}
                        />
                      </div>

                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`duration${props.product.id}`} id={"1"} value={12} onChange={setFirstDuration} label="یک ساله" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`duration${props.product.id}`} id={"2"} value={24} onChange={setFirstDuration} label="دو ساله" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`duration${props.product.id}`} id={"3"} value={36} onChange={setFirstDuration} label="سه ساله" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`duration${props.product.id}`} id={"5"} value={60} onChange={setFirstDuration} label="پنج ساله" />
                      </div>
                    </div>
                  </div>
                )}

                {CountryDataType == "simcard" && (
                  <div className="mt-5 mb-5">
                    <p className="font-bold text-lg mb-3 text-right pr-7">میزان شارژ اولیه سیمکارت</p>
                    <div className="grid mb-3">
                      {parseInt(props.product.pishShomare) === 3725 && (
                        <div className="flex form-check form-check-inline p-1">
                          <CustomRadioInput name={`firstCharge${props.product.id}`} id={"0"} value={0} onChange={setFirstCharge} label="بدون شارژ" checked={true} />
                        </div>
                      )}
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`firstCharge${props.product.id}`} id={"5"} value={5} onChange={setFirstCharge} label="5 دلار" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`firstCharge${props.product.id}`} id={"10"} value={10} onChange={setFirstCharge} label="10 دلار" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`firstCharge${props.product.id}`} id={"30"} value={30} onChange={setFirstCharge} label="30 دلار" />
                      </div>
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`firstCharge${props.product.id}`} id={"50"} value={50} onChange={setFirstCharge} label="50 دلار" />
                      </div>
                    </div>
                  </div>
                )}

                {true === true && (
                  <div className="mt-5 mb-5">
                    <p className="font-bold text-lg mb-3 text-right pr-7">نوع شماره</p>
                    <div className="grid mb-3">
                      <div className="flex form-check form-check-inline p-1">
                        <CustomRadioInput name={`TypeNumber${props.product.id}`} value={"random"} onChange={setTypeNumber} label="رندوم" checked={true} />
                      </div>
                      {selectionList && selectionList.length > 0 && (
                        <div className="flex form-check form-check-inline p-1">
                          <CustomRadioInput name={`TypeNumber${props.product.id}`} value={"selected"} onChange={setTypeNumber} label="انتخابی(هزینه رندوم + 500,000 تومان)" />
                        </div>
                      )}
                      {!isMilad() && (
                        <div className="flex justify-start mx-5 mb-5">
                            <a
                              class="nav-link block pr-2 lg:px-2 py-2 text-[#004296] hover:text-[#007BFF] focus:text-gray-700 transition duration-150 ease-in-out"
                              href={`${apiUrl}/panel/selected/list?type=simcard`}>
                                <button className="bg-blue-700 w-32 text-white rounded-md py-3 mt-5">خرید شماره رند</button>
                              </a>
                            </div>
                      )}
                    
                      {typeNumber === "selected" && (
                        <>
                          <div className="mb-5">
                            <label class="block mt-5 mb-2 text-md font-medium text-red-600 dark:text-gray-400">
                              خواهشمند است 3 شماره را اولویت بندی کنید.
                              <br />
                              به ترتیب اولویت انتخاب شده توسط شما، شماره ای که فروش نرفته باشد برای شما ثبت خواهد شد
                            </label>
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">اولویت های مورد نظر خود را انتخاب کنید</label>
                            <div className="flex items-center border-b-2 py-2">
                              <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                                اولویت اول
                              </label>
                              <select
                                onChange={(e) => setFirstPriority(e.target.value)}
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[70%] mr-5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                style={{ direction: "ltr" }}
                              >
                                <option selected>انتخاب کنید</option>
                                {selectionList &&
                                  selectionList.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div>
                            <div className="flex items-center border-b-2 py-2">
                              <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                                اولویت دوم
                              </label>
                              <select
                                onChange={(e) => setSecondPriority(e.target.value)}
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[70%] mr-5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                style={{ direction: "ltr" }}
                              >
                                <option selected>انتخاب کنید</option>
                                {selectionList &&
                                  selectionList.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div>
                            <div className="flex items-center border-b-2 py-2">
                              <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                                اولویت سوم
                              </label>
                              <select
                                onChange={(e) => setThirdPriority(e.target.value)}
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[70%] mr-5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                style={{ direction: "ltr" }}
                              >
                                <option selected>انتخاب کنید</option>
                                {selectionList &&
                                  selectionList.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                      {typeNumber === "rond" && (
                        <>
                          <p className="my-5">
                            هزینه شماره رند از 100 تا 1000 دلار بالاتر از شماره رندوم می باشد که به هزینه کل محصول شما اضافه میشود،
                            <b className="text-red-700 font-bold"> با انتخاب این گزینه شما هزینه ای پرداخت نمیکنید </b>و فقط
                            <b className="text-red-700 font-bold"> درخواست شما برای ما ارسال میشود </b>و ما نمونه شماره های رند را برای شما ارسال میکنیم و پس از انتخاب و واریزی مبلغ توسط شما ،شماره
                            صادر و در پنل شما قرار میگیرد
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {CountryDataType == "virtual" && (
                  <div className="mb-5">
                    <p className="font-bold text-lg mb-3 text-right pr-7">فعال سازی تماس</p>
                    <div className="grid mb-3">
                      <div className="mb-5 p-2 border-solid border-2 border-green-800">
                        <input
                          onChange={(e) => setActiveDivertCall(e.target.checked)}
                          className="mx-2 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain  mr-2 cursor-pointer"
                          type="checkbox"
                          value="1"
                          id="flexCheckDefault"
                        />
                        در صورت فعال سازی، تماس های دریافتی به این شماره مجازی، به شماره ایران شما دایورت میشود.
                        <br />
                        برای وریفای سرویس هایی نظیر واتس آپ و اپل آیدی راه اندازی این سرویس الزامی است هزینه این سرویس 2 دلار می باشد و 2 دلار پنل کاربری شما شارژ می شود
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-5 p-2 border-solid border-2 border-sky-600">
                  <input
                    onChange={(e) => setAcceptRule(e.target.checked)}
                    className="mx-2 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain  mr-2 cursor-pointer"
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault"
                  />
                  <span className="bg-emerald-700 text-white  ml-1 px-2">
                    <b onClick={() => setShowRule(!showRule)}>متن قوانین </b>
                  </span>
                  را خوانده ام و آن را قبول دارم
                  {showAlerAcceptRule && (
                    <>
                      <br />
                      <br />
                      <b className="text-red-500">لطفا تیک متن قوانین را خوانده ام را بزنید</b>
                    </>
                  )}
                </div>
                <div className={`text-gray-700  font-bold p-4  rounded-md text-right ${showRule ? "block" : "hidden"} `}>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: props.product.ruls }} />
                  </div>

                  <br />
                  <br />
                  <b className="mt-5 p-2 border-solid border-2 bg-white text-red-500 border-red-800" onClick={() => setShowRule(!showRule)}>
                    بستن متن قوانین{" "}
                  </b>

                  <br />
                </div>

                {typeNumber !== "rond" && (
                  <div className="text-indigo-700 font-bold text-md md:text-lg   my-2">
                    {props.name} + ۹ درصد مالیات ارزش افزوده
                    <br />
                    {CalculatePrice()} تومان
                  </div>
                )}

                {activeSpinner && (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}

                {!activeSpinner && typeNumber === "rond" && (
                  <button
                    data-bs-dismiss={pwaAccessToken && acceptRule ? "modal" : "zzzz"}
                    onClick={() => goNextStep()}
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-lg leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    ثبت سفارش
                  </button>
                )}
                {!activeSpinner && typeNumber !== "rond" && (
                  <button
                    onClick={() => goNextStep()}
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-lg leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    برای پرداخت کلیک کنید
                  </button>
                )}
              </div>
            )}

            {step === "getMobile" && (
              <div className="modal-body  p-4 ">
                <div className="mb-4">
                  <div className="text-right">
                    <CustomLabel name="موبایل شما(با 09 شروع شود)" />
                  </div>
                  <CustomInput type="tel" onChange={setMobile} />
                </div>

                <div className="text-indigo-700 font-bold text-lg my-2">
                  {props.name}
                  <br />
                  {typeNumber !== "rond" && <>{CalculatePrice()} تومان</>}
                </div>

                {activeSpinner && (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}

                {!activeSpinner && (
                  <div className="bg-[#00ABC1] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => Login()}>
                    ادامه
                  </div>
                )}
              </div>
            )}
            {step === "getCode" && (
              <div className="modal-body  p-4 ">
                {isRegistered === false && (
                  <div className="mb-4">
                    <CustomLabel name={"نام"} />
                    <CustomInput name={"name"} type="string" onChange={setName} />
                  </div>
                )}

                <div className="mb-4">
                  <CustomLabel name={"کد تایید"} />
                  <CustomInput name={"code"} type="tel" onChange={setCode} />
                </div>

                {activeSpinner && (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}

                {!activeSpinner && (
                  <div className="bg-[#00ABC1] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => Login()}>
                    مرحله بعد
                  </div>
                )}
              </div>
            )}
            {step === "getUserOrderData" && (
              <div className="modal-body  p-4 ">
                <div className="mb-4">
                  <div className="text-right">
                    <CustomLabel name="آدرس" />
                  </div>
                  <CustomInput onChange={setAddress} />
                </div>
                <div className="mb-4">
                  <div className="text-right">
                    <CustomLabel name="کد پستی" />
                  </div>
                  <CustomInput onChange={setCodePost} />
                </div>

                <div className="mb-4">
                  <div className="text-right">
                    <CustomLabel name="عکس از کارت ملی" />
                  </div>

                  <input
                    onChange={(e) => setSelectedFileFront(e.target.files)}
                    className="self-center form-control
block
w-full
px-3
py-1
text-base
font-normal
text-gray-700
bg-white bg-clip-padding
border border-solid border-gray-300
rounded
transition
ease-in-out
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    type="file"
                    id="formFile"
                  />
                </div>
                <div className="text-indigo-700 font-bold text-lg my-2">{props.name}</div>

                {activeSpinner && (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}

                {!activeSpinner && (
                  <>
                    <button className="bg-[#00ABC1] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => pay()}>
                      پرداخت با درگاه پرداخت بانک سامان
                      <br />
                      {CalculatePrice()} تومان
                    </button>
                    {/* <button className="bg-[#00c177] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => pay(true)}>
                      پرداخت با رمزارز تتر (USDT)
                      <br />$ {CalculatePrice(true)}
                    </button> */}
                    <button className="mt-10 self-end  p-5 text-red-700" onClick={() => setStep("getFirstDetail")}>
                      برگشت
                    </button>
                  </>
                )}
              </div>
            )}

            {step === "chooseMethod" && (
              <>
                <div className="modal-body  p-4 ">
                  <button className="bg-[#00ABC1] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => pay()}>
                    پرداخت با درگاه پرداخت بانک سامان
                    <br />
                    {CalculatePrice()} تومان
                  </button>
                  {/* <button className="bg-[#00c177] w-full py-4 text-center rounded-xl text-m text-white  mt-2 " onClick={() => pay(true)}>
                    پرداخت با رمزارز تتر (USDT)
                    <br />$ {CalculatePrice(true)}
                  </button> */}

                  <button className="mt-10 self-end  p-5 text-red-700" onClick={() => setStep("getFirstDetail")}>
                    برگشت
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
